.login_layout {
    background: linear-gradient(360deg, #34d2e4 0%, #1890ff 91.11%) !important;
}
.content {
    height: 100vh;
}

.content_row {
    height: 100%;
}

.login_card {
    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.25);
    border-radius: 15px !important;
    width: 450px;
}

.login_logo_img {
    width: 206px;
    // height: 83px;
    display: flex;
    justify-content: center;
}

.sip-table .ant-table-thead > tr > th {
    color: white;
    font-weight: 750;
    font-family: montserrat, sans-serif;
}

