.top-bar {
    height: 100px;
    /*display: flex;*/
    background-color: rgb(28, 70, 124);
    img {
        max-height: 100%;
        height: 100px;
    }
    .right-area {
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;
        padding-bottom: 5px;
    }
    .top-bar-username {
        color: white;
    }
    .ant-menu-item {
        a {
            color: #fff !important;
        }
    }
}
