.compare-button {
    padding-left: 5px;
    padding-right: 10px;
    margin-top: 15px;
}

.location-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #124354 !important;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.location-button span {
    color: #fff;
    /*height: 20px; */
}

.compare-export-button {
    padding-left: 5px;
    padding-right: 10px;
    margin-top: 15px;
}

.search-location-radio {
    display: flex;
    align-items: center;
}

#portfolio_select .ant-select-selection-placeholder {
    opacity: 1.0 !important;
}
