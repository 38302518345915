.sip-admin-screen-container {
    height: 100%;
    .admin-sidebar {
        height: 100%;
        background-color: rgb(221, 221, 221);
        .ant-layout-sider-children {
            text-align: center;
            .sidebar-button {
                width: 90%;
                margin: 10px 0px;
            }
        }
    }
    .admin-content-section {
        .admin-actions-row {
            .admin-actions-button {
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 15px;
                margin-left: 5px;
            }
        }
    }
}

.admin-table .ant-table-thead .ant-table-cell {
    color: white !important;
    font-family: montserrat, sans-serif;
    font-weight: 750;
}
