// .top-bar {
//     float: right;

//     img.nav-logo {
//         position: absolute;
//         left: -35px;
//         top: -25px;
//         width: 250px;
//     }

//     .ant-menu {
//         border-bottom: none;
//     }
//     .top-menu .ant-menu-item a {
//         color: white;
//     }
//     .ant-menu-item-selected,
//     .ant-menu-item-active,
//     .ant-menu-item-active:hover {
//         border-bottom: 2px solid white !important;
//         color: white;
//     }

//     .ant-menu-item-active a {
//         color: #7cb6e4 !important;
//     }
// }

.login-inactive {
    background-color: #1c467c;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: montserrat, sans-serif;

    .inactive-container {
        background-color: white;
        height: 200px;
        text-align: center;
        min-width: 400px;
        font-weight: 600;

        .inactive-title {
            background-color: #88b725;
            color: white;
            font-size: 2em;
        }
    }
}
.login-no-auth {
    background-color: #1c467c;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-family: montserrat, sans-serif;

    .google-div {
        margin-top: 15px;
        display: flex;
        justify-content: center;
    }

    .login-title {
        margin-top: -115px;
        display: flex;
        justify-content: center;
        color: white !important;
    }

    .logo-img {
        display: flex;
        justify-content: center;
    }
}

.item-list-button {
    margin-left: 5px;
}

.item-status-details {
    margin-right: 55px;
}

.ant-spin-spinning {
    display: block !important;
}

.compare-highlight .ant-table-cell {
    border-top-color: #88b725 !important;
    border-top-style: solid !important;
    border-top-width: 5px !important;
    border-bottom-color: #88b725 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 5px !important;
}
.compare-highlight .ant-table-cell {
    border-top-color: #88b725 !important;
    border-top-style: solid !important;
    border-top-width: 5px !important;
    border-bottom-color: #88b725 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 5px !important;
}

.compare-highlight .ant-table-cell-fix-left {
    border-left-color: #88b725 !important;
    border-left-style: solid !important;
    border-left-width: 5px !important;
}

.compare-highlight .ant-table-cell-fix-right {
    border-right-color: #88b725 !important;
    border-right-style: solid !important;
    border-right-width: 5px !important;
}

.alternate-row,
.alternate-row .ant-table-cell-fix-left,
.alternate-row .ant-table-cell-fix-right,
.alternate-row .ant-table-column-sort {
    background-color: #dfe7c3;
}

.sip-table .ant-table-thead th {
    background-color: #1c467c;
}

.admin-table .ant-table-thead th {
    background-color: #1c467c;
}

//thead[class*='ant-table-thead'] th {
//    background-color: #1c467c !important;
//}

thead[class*='ant-table-thead'] th span {
    color: white !important;
    font-family: montserrat, sans-serif;
    font-weight: 750;
}

.compare-table .ant-table-thead th {
    background-color: #1c467c;
}

.compare-table .ant-table-thead .ant-table-cell {
    color: white !important;
    font-family: montserrat, sans-serif;
    font-weight: 750;
}

.analyze-table .ant-pagination {
    font-size: 12px !important;
}

.analyze-table .ant-table-pagination.ant-pagination {
    margin: 5px !important;
}

.analyze-table .ant-table-thead .ant-table-cell {
    color: black !important;
    background: white !important;
    font-family: montserrat, sans-serif;
    font-weight: 750;
}

.analyze-table .ant-select-single .ant-select-selector {
    display: none !important;
}

.analyze-card .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 22px !important;
}
.analyze-meta .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
}

.details-modal .ant-modal-close {
    color: white;
}
.details-modal .ant-modal-header,
.details-modal .ant-modal-footer {
    background-color: #88b725;

    .ant-modal-title {
        color: white;
        font-family: montserrat, sans-serif;
        font-weight: 750;
    }
}

.dashboard-modal-form-item .ant-input[disabled] {
    color: black;
    background-color: white;
    cursor: not-allowed;
    opacity: 2;
}

.financial-highlights-link {
    color: #88b725;
}

.financial-highlights-link:hover {
    // color: white;
    color: #88b725;
    text-decoration: underline;
}

.analyze-modal-button {
    color: #1c467c;
    cursor: pointer;
}

.analyze-modal-button:hover {
    color: #1c467c;
    text-decoration: underline;
}

.export-button {
    background-color: rgb(28, 70, 124) !important;
    color: white !important;
}

.th-filter {
    background-color: rgb(136, 183, 37) !important;
    color: white;
}