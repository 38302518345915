.search-row {
    display: flex;
    justify-content: space-around;
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 10;
    align-items: center;
    padding-bottom: 10px;
}

.rc-virtual-list-scrollbar {
    display: block !important;
}